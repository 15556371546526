import React, {useState}from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import Form from './modules/Form';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://grupohimmel.cl/">
        Himmel
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const RUT_REGEX = /^\d{1,2}.\d{3}.\d{1,3}-[k|K|\d]{1}$/;


function validaRut(rut) {
  if (!RUT_REGEX.test(rut)) return false;

  rut = rut.replace(/\./g, '').split('-');
  let numero = rut[0];
  let dv = rut[1].toUpperCase();
  let factor = 2;
  let suma = 0;

  for (let i = numero.length - 1; i >= 0; i--) {
    suma += numero.charAt(i) * factor;
    factor = (factor === 7) ? 2 : factor + 1;
  }

  let mod = 11 - (suma % 11);
  if (mod === 11) mod = 0;
  if (mod === 10) mod = 'K';

  return mod.toString() === dv;
}
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const containerStyle = {
  backgroundImage: 'url(https://grupohimmel.cl/wp-content/uploads/2022/10/Monte-Financiero.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh', // Adjust the height as needed
};


export default function App() {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [termsAgree, setTermsAgree] = useState(false)
  const [companyName, setCompanyName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');

  const validation = () => {
    if (!companyName.length)  {
      setLoading(false)
      alert('Debe poner un nombre de empresa')
      return false
    }
    if (!password.length)  {
      setLoading(false)
      alert('Debe poner una contraseña')
      return false
    }
    if (!termsAgree)  {
      setLoading(false)
      alert('Debe aceptar los términos y condiciones.')
      return false
    }
    if (!validaRut(identifier))  {
      setLoading(false)
      alert('Rut inválido')
      return false
    }
    return true
  }
  const resetForm = async () => {
    setCompanyName(null)
    setIdentifier(null)
    setPassword(null)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(termsAgree, identifier, companyName, password)
    if (!validation()) return 
    setOpen(false)
    setLoading(true)
    console.log(termsAgree, identifier, companyName, password)
    const url = 'https://t7525zyn2d.execute-api.us-east-1.amazonaws.com/default/form-sii'
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    try {
      const response = await axios.post(`${url}?companyName=${companyName}&password=${password}&identifier=${identifier}`)
    } catch (error) {
      console.log(error)
    }
    setTermsAgree(false)
    setOpen(true)
    resetForm()
    setLoading(false)
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loading ? <div style={{margin: '17% 48%'}}> <CircularProgress /> </div> :
      (
        <Grid container>
           <Grid item xs={0} md={6} style={containerStyle}>
      </Grid>
           <Grid xs={12} md={6}>
            <Form />
          </Grid>
        </Grid>
      )
     }
    </ThemeProvider>
  );
}